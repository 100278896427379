export const deliveredProductsByBar = {
	tabTitle: "Produtos entregues por bar",
	filename: "produtos-entregues",
	selectedBars: "Bares selecionados",
	noSelectedBar: "Selecione ao menos um bar para ver o relatório",
	table: {
		total: "Total",
		name: "Nome",
		count: "Unidades Vendidas",
		fracionalCount: "Quantidade Fracionária",
		fracCount: "Quantidade Frac.",
		unitCost: "Valor Unitário",
		proportionalValue: "Valor Proporcional",
		subtotal: "Subtotal",
		totalDiscount: "Descontos",
		totalVat: "Total IVA",
		valueVat: "IVA",
		totalValue: "Valor Total",
		category: "Categoria",
	},
	bar: "Bar",
	selectPlaceholder: "Selecione Bar(es)",
	all: "Todos",
	selectVisualization: "Selecione visualização",
	byBars: "Por bares",
	transactionType: "Tipo de transação",
	bars: "Bares",
	productMasterKinds: "Tipos de produtos",
	applyFilters: "Aplicar filtros",
	total: "Total",
	emptyState: {
		withoutFilters: {
			title: "Sem filtros aplicados",
			description: "Selecione pelo menos um bar para gerar um relatório.",
		},
		anyResults: {
			title: "Nenhum resultado encontrado",
			description:
				"Não foi possível encontrar resultados compatíveis com os filtros aplicados!",
		},
	},
	totalizer: {
		totalUnitsSold: "Total de unidades vendidas",
		totalInDiscounts: "Total em descontos",
		totalValueSold: "Valor total vendido",
	},
};
