export const storePT = {
	backofficeStore: {
		createTransfer: "Transferência realizada com sucesso",
		editTransfer: "Transferência editada com sucesso",
		deleteTransfer: "Transferência apagada com sucesso",
		editBill: "Conta editada com sucesso",
		deleteBill: "Conta deletada",
		importBackofficeBills: "Contas importadas",
		createBillTypes: "Tipo de conta criada com sucesso",
		createCostCenters: "Centro de custo criado",
		deleteCostCenter: "Centro de custo apagado",
		editCostCenter: "Centro de custo editado",
		deleteAccount: "Conta de cliente apagada",
		editAccount: "Conta de cliente editada",
		editBillTypes: "Tipo de conta editada",
		deleteBillTypes: "Tipo de conta deletada",
		createCategorie: "Categoria criada",
		editCategorie: "Categoria editada",
		deleteCategorie: "Categoria apagada",
		addStorage: "Estoque criado com sucesso",
		updateStorage: "Estoque modificado com sucesso",
		updateBar: "Bar editado com sucesso",
		deleteBar: "Bar apagado com sucesso",
		addBar: "Bar adicionado",
		createNewSupplier: "Novo fornecedor adicionado com sucesso",
		inputBackofficeProductsV2_zero: "Produtos adicionados ao estoque",
		inputBackofficeProductsV2_other: "Um ou mais produtos não foram adicionados",
		zeroBackofficeAllProductsAtStorage: "Todos os produtos zerados",
		editSupplier: "Fornecedor editado com sucesso",
		saveBackofficeProductsQuantityV2: "Produto atualizado",
		deleteBackofficeProduct: "Produto deletado do estoque",
		deleteBackofficeProducts: "Todos os produtos foram deletados do estoque",
		deleteSupplier: "Fornecedor excluído com sucesso",
		transferBackofficeProduct: "Produto transferido",
		createAccount: "Conta lançada com sucesso",
		deleteInvoices: "Notas fiscais excluídas com sucesso",
	},
	barStore: {
		deleteBar: "Bar removido.",
		editBar: "Bar atualizado.",
		addTableBarRange: "Configuração de impressão por mesa adicionada.",
		editTableBarRange: "Configuração de impressão por mesa atualizada.",
		deleteRange: "Configuração de impressão por mesa removida.",
	},
	cmvStore: {
		error: {
			getCategories: "Erro ao buscar categoria",
		},
		updatedProductsWithoutCostOnSells: "Custo atualizado com sucesso",
	},
	cashierStore: {
		bleedCashier_zero: "Fundo de troco realizado com sucesso",
		bleedCashier_other: "Sangria realizada com sucesso.",
		addCashierClosingObs: "Observação adicionada.",
		createCashierChangeLEGACY: "Fundo de troco adicionado",
		createMultipleChangesLEGACY: "Todos fundos foram cadastrados",
		createMultipleChangesLEGACYError:
			"{{errors}} de {{cashiersId}} deram erro: {{jsonErrors}}",
		createBleedLEGACY: "Sangria adicionada",
		updateCashierClosingLEGACY: "Caixa fechado com sucesso",
	},
	clientStore: {
		getClientByCPFOrPhone: "Cliente adicionado",
		editClientDetails: "Detalhes de cliente atualizados.",
		editClientDetailsPlace: "Detalhes de cliente atualizados.",
		editUserDetails: "Detalhes de cliente atualizados.",
		payBillAtPlace: "Conta paga com sucesso",
		payAllBillsAtPlace: "Contas pagas com sucesso",
		clientAlreadyAdded: "Esse cliente ja foi adicionado",
		payBillsSuccess: "Contas pagas com sucesso",
		payBillsWarning:
			"{{success}} contas pagas com sucesso, e {{errors}} erros ao pagar contas.",
		payBillsLoading: "Marcando como pago",
		createPixCharge: "PIX gerado com sucesso",
		updatedProductsWithoutCostOnSells: "Custo atualizado com sucesso",
		refundAppSell:
			"Transação estornada com sucesso! Atualize a página para visualizar as informações corretamente.",
		cancelAppSell:
			"Transação cancelada com sucesso! Atualize a página para visualizar as informações corretamente.",
	},
	comboStore: {
		createCombo: "Combo criado.",
		deleteCombo: "Combo removido.",
		editCombo: "Combo atualizado.",
	},
	deliveryStore: {
		deleteMenu: "Cardápio apagado com sucesso!",
		addProduct: "Produto adicionado com sucesso!",
		addProductV2: "Produto adicionado com sucesso!",
		activateManyProductsLoading: "Ativando produtos",
		activateManyProductsWarning:
			"{{countFailures}} produto(s) não puderam ser atualizados.",
		deactivateManyProductsLoading: "Desativando produtos",
		deactivateManyProductsWarning:
			"{{countFailures}} produto(s) não puderam ser atualizados.",
	},
	deviceStore: {
		removeDeviceFromEvent: "Dispositivo retirado do evento.",
		updateEmployeeDeviceConfigs: "PDV configurado com sucesso",
		addDevice: "Dispositivo adicionado ao Local com sucesso",
	},
	employeeStore: {
		addEmployee: "Funcionário adicionado.",
		editEmployee: "Funcionário atualizado.",
		removeEmployee: "Funcionário removido.",
		addMultipleEmployees: {
			error: "Colaboradores não adicionados:",
			success: "Colaboradores adicionados com sucesso:",
		},
		editMultipleEmployees: "Colaboradores modificados com sucesso",
		removeMultipleEmployees: "{{successCount}} colaboradores removidos com sucesso",
		removeMultipleEmployeesError:
			"{{errorCount}} colaboradores não puderam ser removidos",
		createRole: "Cargo criado com sucesso",
		deleteRole: "Cargo removido com sucesso",
		updateRole: "Cargo editado com sucesso",
	},
	eventStore: {
		createEvent: "Evento criado.",
		openEvent: "Evento aberto.",
		payBillAtEventWithMultiplePayments: "Conta paga com sucesso",
		payAllBillsAtEvent: "Contas pagas com sucesso",
		closeEvent: "Evento fechado.",
		eventEditedSuccessfully: "Evento editado com sucesso",
		closeEventLoading: "Fechando evento",
		deleteEvent: "Evento removido.",
		emitInnovaroGeneralInvoices: "Notas emitidas com sucesso",
		refundFichaSell: "Ficha estornada com sucesso",
		giveRechargeToUser: "Recarga efetuada com sucesso",
	},
	extractStore: {
		createBankAccount: "Conta bancária criada com sucesso.",
		deleteBankAccount: "Conta bancária removida com sucesso!",
		updateBankAccount: "Conta bancária atualizada com sucesso!",
		createAdjustmentInVendorExtract: "Ajuste adicionado com sucesso",
		payVendorWithdrawRequests: "Aprovações enviadas com sucesso!",
		revertManyVendorsWithDrawRequests: "Rejeição de solicitação enviada com sucesso!",
		orderWithdrawCancellation: "Solicitação de cancelamento realizada com sucesso.",
	},
	fiscalStore: {
		createFiscalProfile: "Sucesso ao criar perfil fiscal.",
		editFiscalProfile: "Sucesso ao atualizar perfil fiscal.",
		createFiscalProductGroup: "Sucesso ao criar grupo fiscal.",
		editFiscalProductGroup: "Sucesso ao atualizar grupo fiscal.",
		deleteFiscalProductGroup: "Sucesso ao atualizar grupo fiscal.",
		issueManualInvoiceForReserveError_zero:
			"Erro ao gerar {{failedInvoiceCount}} notas fiscais",
		issueManualInvoiceForReserveError: "Erro n°{{number}}: {{error}}",
		issueManualInvoiceForReserve_zero: "Todas Notas fiscais geradas com sucesso",
		issueManualInvoiceForReserve_other:
			"{{issuedInvoices}} Notas fiscais geradas com sucesso",
		minimumConsumptionFiscalSuccess: "Consumação mínima configurada com sucesso",
		rechargeFiscalSuccess: "Recarga configurada com sucesso",
	},
	invoiceStore: {
		notifyOfIssuing_zero: "Não havia notas a emitir",
		notifyOfIssuing_other: "{{invoiceCount}} notas fiscais emitidas",
		notifyOfIssuingWarning_other:
			"{{issuedInvoices}} notas fiscais emitidas, mas os seguintes erros foram encontrados: {{errors}}",
		notifyOfIssuingWarning_zero:
			"Nenhuma nota fiscal emitida, os seguintes erros foram encontrados: {{errors}}",
		issueAllEventInvoices: "Notas fiscais de evento emitidas.",
		sendInvoicesByEmail: "Notas enviadas por email.",
		cancelInvoice: "Nota fiscal cancelada com sucesso!",
		cancelInvoiceNC:
			"Nota de crédito gerada com sucesso! Sua nota de crédito estará disponível em breve.",
		issueInvoicesOfTransactions: "Notas fiscais emitidas",
	},
	itemStore: {
		activateProductsInPlaceLoading: "Ativando produtos",
		activateProductsInPlace: "Produtos ativados",
		deactivateProductsInPlaceLoading: "Desativando produtos",
		deactivateProductsInPlace: "Produtos desativados",
		createCategory: "Categoria criada com sucesso",
		editCategory: "Categoria atualizada",
		deleteCategory: "Categoria deletada",
		addProduct: "Produto adicionado",
		importProductsError: "Erro na edição do produto {{name}}: {{message}}",
		importProducts: "{{products}} Produtos importados",
		importProductsWarning:
			"{{products}} Produtos importados e {{errorCounter}} produtos tiveram problemas na importação",
		editProduct: "Produto atualizado",
		importProductsToEditError: "Erro na edição do produto {{name}}: {{message}}",
		importProductsToEdit: "{{products}} Produtos editados",
		importProductsToEditWarning:
			"{{products}} Produtos editados e {{errorCounter}} produtos tiveram problemas na edição",
		deleteProduct: "Produto removido",
		deleteProductError: "Erro ao remover produto",
		deleteProductFromPlace: "Produto removido",
		activateMountableSectionItem_all: "Itens ativados com sucesso",
		activateMountableSectionItem_one: "Item ativado com sucesso",
		deactivateMountableSectionItem_all: "Itens desativados com sucesso",
		deactivateMountableSectionItem_one: "Item desativado com sucesso",
		addSupply: "Insumo adicionado",
		editSupply: "Insumo alterado",
		createEntrance: "Entrada adicionada",
		deleteEntrance: "Entrada removida",
		editEntrance: "Entrada atualizada",
		editEntranceImage: "Entrada atualizada",
		attachProductionRule: "Ficha técnica criada com sucesso",
		removeProductionRule: "Ficha técnica removida com sucesso",
		editProductionRule: "Regra de produção atualizada",
		removedProductionRuleInput: "Insumo removido com sucesso",
		editMultipleProducts: "{{name}} modificado com sucesso!",
		editMultipleProductsError: "{{name}} não pôde ser alterado: {{reason}}",
		deleteMultipleProducts: "Removidos com sucesso",
		createBeerTap: "Chopeira criada com sucesso!",
		editBeerTap: "Chopeira edita com sucesso!",
		removeBeerTap: "Chopeira deletada com sucesso!",
		activatingProduct: "Ativando produto",
		deactivatingProduct: "Desativando produto",
		activatedProduct: "Produto ativado",
		deactivatedProduct: "Produto desativado",
		activatingEntrance: "Ativando entrada",
		deactivatingEntrance: "Desativando entrada",
		activatedEntrance: "Entrada ativada",
		deactivatedEntrance: "Entrada desativada",
		errorToAdd: "Erro ao adicionar {{name}}",
	},
	organizationStore: {
		addGiftback: "Giftback criado com sucesso",
		addProductToOrg: "Produto adicionado",
		addMultipleOrgProducts: "Produtos Adicionados",
		editGiftback: "Giftback alterado com sucesso",
		editOrgProduct: "Produto editado",
		editMultipleOrgProducts: "Produtos editados",
		deleteMultipleOrgProducts:
			"({{products}}) produtos foram removidos do inventário de todos os locais.",
		deleteGiftback: "Giftback removido com sucesso",
		deleteProduct: "Produto removido",
		deleteProductError: "Erro ao remover produto",
		tooglePlaces: "Locais alterados",
		addMultipleProductsInPlace: "Locais alterados",
		activateGiftback: "Giftback ativado com sucesso",
		deactivateGiftback: "Giftback desativado com sucesso",
	},
	placeStore: {
		editEverestConfigWarning: "Validando dados com a everest",
		editEverestConfig: "Dados da everest atualizados.",
		editPlace: "Local atualizado.",
		confirmSignPlaceContracts: "A assinatura foi concluída  com sucesso.",
		createStorageAlerts: "Alerta criado com sucesso",
		editStorageAlerts: "Alerta editado com sucesso",
		deleteStorageAlerts: "Alerta excluido com sucesso",
		addEmailToMinimumStorageAlert: "Emails cadastrados com sucesso",
		removeEmailToMinimumStorageAlert: "Email removido com sucesso",
		deleteDiscountCategory: "Desconto removido com sucesso!",
		activateDiscountCategory: "Desconto ativado com sucesso!",
		disableDiscountCategory: "Desconto desativado com sucesso!",
		createManualLostReport: "Perda manual criada com sucesso.",
		updatePlaceSettingsGeneralSection: "Configurações gerais atualizadas.",
		updatePlaceSettingsFichaSection: "Configurações de ficha atualizadas.",
		updatePlaceSettingsCouvertSection: "Configurações de Couvert atualizadas.",
		updatePlaceSettingsPlaceFeatureSection:
			"Configurações das funcionalidades atualizadas.",
		updatePlaceSettingsPDVSection: "Configurações de PDV atualizadas.",
		updatePlaceSettingsTotemSection: "Configurações de Totem atualizadas.",
		createPixCharge: "PIX gerado com sucesso",
	},
	productFeatureStore: {
		org: "ORG",
		zig: "ZIG",
		createProductFeatureTemplate:
			"Modelo de funcionalidade de produto criada com sucesso!",
		editProductFeatureTemplate:
			"Modelo de funcionalidade de produto editado com sucesso!",
		removeProductFeatureTemplate:
			"Modelo de funcionalidade de produto removido com sucesso!",
		createProductFeature: "Funcionalidade do produto criada com sucesso!",
		editProductFeature: "Funcionalidade do produto editada com sucesso!",
		removeProductFeature: "Funcionalidade do produto removida com sucesso!",
		removeProductFeaturesByTemplateId:
			"Funcionalidades de produto ligadas ao modelo foram removidas com sucesso!",
	},
	promotionStore: {
		createPromotion: "Promoção criada com sucesso",
		editPromotion: "Promoção editada com sucesso",
		updatePromotionPeople: "Clientes atualizados com sucesso",
		usersInfoRewriteLoading: "Buscando dados dos clientes, isso pode demorar bastante",
	},
	relationStore: {
		editRelation: "Informações da lista editadas com sucesso!",
		deleteRelation: "Lista apagada com sucesso",
		deleteMultipleRelation_one: "Lista apagada com sucesso",
		deleteMultipleRelation_other: "Listas apagadas com sucesso",
		addRelationUser: "Convidado adicionado com sucesso!",
		editRelationUser: "Convidado editado com sucesso!",
		deleteRelationUser: "Usuário apagado da lista com sucesso",
		activateOrDeactivateRelation_active: "Listas ativada com sucesso",
		activateOrDeactivateRelation_deactive: "Lista desativada com sucesso",
	},
	reserveStore: {
		addReserve: "Pacote criado com sucesso",
		editReserve: "Pacote modificado com sucesso",
		deleteReserve: "Pacote apagado com sucesso",
		createReserveTemplate: "Modelo de pacote criado com sucesso!",
	},
	smsStore: {
		cancelSmsCampaign: "Cancelamento do agendamento foi realizado.",
		editSmsCampaignClients: "Grupo de destinatários alterado.",
	},
	vendorStore: {
		createVendor: "Vendor criado com sucesso",
		editVendor: "Vendor editado com sucesso",
	},
	whatsUpStore: {
		setPlaceDetailStatus_active: "Local ativo no Zig App",
		setPlaceDetailStatus_inactive: "Local inativo no Zig App",
	},
};
