export const teamEN = {
	custom: "Custom",
	showTableByBar: "Show table by bar",
	noEmployees: "No collaborators found",
	notifications: {
		removeEmployee: "Collaborator removed successfully",
		removeMultipleEmployees: "Collaborators removed successfully",
		addEmployee: "Collaborator added successfully",
		addZigTagToWaiter: "ZigTag added successfully",
		addMultipleEmployees: {
			error: "Collaborators not added:",
			success: "Collaborators added successfully:",
		},
		editEmployee: "Collaborator edited successfully",
		editMultipleEmployees: "Collaborators edited successfully",
		changePlace_one: "Employee transferred successfully!",
		changePlace_other: "Employees transferred successfully!",
		changePlaceError: "were not transferred",
	},
	header: {
		titleOrg: "Team",
		titlePlace: "Operators of PDV",
		descriptionOrg:
			"Add, modify and delete collaborators from the Organization (dashboard users).",
		descriptionPlace: "Add, modify and delete PDV operators at {{ placeName }}",
		alert: {
			link: "access the team section of the organization.",
			text: "To add dashboard collaborators or manage positions,",
		},
		searchPlaceholder: "Search",
		searchEmployee: "Search for a collaborator",
		filterByPlace: "Filter by place",
		closeSearch: "Close",
		cleanSearch: "Clear",
		closeFilter: "Close filter",
		cleanFilter: "Clear filter",
	},
	headerActions: {
		addEmployee: "Add employee",
		importEmployees: "Import employees",
		selectAllEmployee: "Select all employees ({{ count }})",
		editSelectedCollaborator_one: "Edit selected collaborator ({{count}})",
		editSelectedCollaborator_other: "Edit selected collaborators ({{count}}) ",
		deleteSelectedCollaborator_one: "Delete selected collaborator ({{count}})",
		deleteSelectedCollaborator_other: "Delete selected collaborators ({{count}}) ",
		uncheckEmployee_one: "Uncheck selected collaborator ({{count}})",
		uncheckEmployee_other: "Uncheck selected collaborators ({{count}}) ",
		changePlaceSelectedCollaborator_one:
			"Change place of selected collaborator ({{count}})",
		changePlaceSelectedCollaborator_other:
			"Change place of selected collaborators ({{count}})",
		buttonBulkActions: {
			titleBtn: "Bulk actions",
			titleModal: "Bulk actions ({{ count }} selected)",
			titleModalMode: "Selection mode",
			removeSelected: "Remove selection of operators ({{count}})",
			selectAllByRole: "Select all operators by role",
			selectRole: "Select all {{name}}",
			titleRole: "Custom",
			editSelectedCollaborator_one: "Edit selected collaborator",
			editSelectedCollaborator_other: "Edit selected collaborators",
			deleteSelectedCollaborator_one: "Delete selected collaborator",
			deleteSelectedCollaborator_other: "Delete selected collaborators",
			changePlaceSelectedCollaborator_one: "Change place of selected collaborator",
			changePlaceSelectedCollaborator_other: "Change place of selected collaborators",
		},
	},
	columns: {
		name: "Collaborators",
		username: "Username",
		role: "Role",
		pub: {
			none: "No bar",
			label: "Bars",
			mainBar: "Main Bar",
			bars: "Secondary bars",
		},
		places: "Places",
		placesNone: "No places selected",
		deleteEmployee: "Delete employee",
		updateEmployee: "Update employee",
		duplicateEmployee: "Duplicate employee",
		changePlace: "Change location",
	},
	form: {
		create: {
			title: "New Operator",
			descriptionOrg: "Create a collaborator for the organization (dashboard user)",
			descriptionPlace: "Create an operator at {{ placeName }}",
		},
		update: {
			title: "Edit Operator",
			descriptionOrg: "Edit a collaborator for the organization (dashboard user)",
			descriptionPlace: "Edit an operator at {{ placeName }}",
		},
		multipleUpdate: {
			title: "Edit {{count}} Operators",
			descriptionOrg:
				"Edit the selected collaborators for the organization (dashboard users)",
			descriptionPlace: "Edit {{count}} operators at {{ placeName }}",
			warning: {
				title: "Fields not filled",
				description:
					"The unfilled fields maintain the original values of the collaborator.",
			},
		},
		actions: {
			discardCreate: "Discard creation",
			discardEdit: "Discard changes",
			create: "Create collaborator",
			save: "Save changes",
		},
		dataEmployees: {
			title: "Employee Data",
			name: {
				label: "Name",
				placeholder: "Enter the name",
				required: "Name is required",
				min: "Name must have at least 3 characters",
			},
			username: {
				label: "Username",
				placeholder: "Enter the username",
				required: "Username is required",
				lowercase: "Username must be lowercase",
				noSpaces: "Username cannot have spaces",
				tooltip: "Username cannot be edited",
			},
			password: {
				label: "Password",
				placeholder: "Create the access password",
				required: "Password is required",
				min: "Password must have at least 6 characters",
				notEmpty: "Password cannot be empty",
			},
		},
		renameDataEmployees: {
			title: "Name",
			name: {
				label: "Rename",
				placeholder: "Rename collaborators in bulk",
			},
			initialNameNumber: {
				label: "Number from",
				placeholder: "Enter the initial value",
				nonnegative: "The value must be greater than 0",
				required: "The field is required",
			},
			renameInitialNameNumber: {
				validation: "The fields must be filled in together",
			},
			info:
				"When renaming in bulk, all selected collaborators will have the same name. To differentiate, we automatically number them starting from the number you enter in the text box above",
		},
		placeSection: {
			title: "Places",
			selectedPlaces: {
				required: "At least one place must be selected",
				selectAll: "Select all",
				none: "No places selected",
				min: "At least one place must be selected",
			},
		},
		extraInfoSection: {
			title: "Extra Information",
			pub: {
				label: "Bars",
				labelMainBar: "Main Bar / Point of Sale",
				placeholder: "Select a bar from {{ placeName }}",
			},
			fichasAmount: {
				label: "Number of copies on the ticket",
				placeholder: "Enter the quantity",
				nonnegative: "The quantity must be greater than or equal to 0",
			},
			menuVisualizationType: {
				label: "Menu visualization",
				placeholder: "Enter the quantity",
				tooltip: {
					Grid: "Large photo: 2 columns",
					SmallGrid: "Small photo: 3 columns",
					NoPicture: "No photo: 3 columns",
				},
				type: {
					Grid: "Large photo",
					SmallGrid: "Small photo",
					NoPicture: "No photo",
				},
			},
		},
		rolesAndPermissions: {
			titleOrg: "Roles and permissions of the dashboard",
			titlePlace: "Roles and permissions of the PDV",
			employeeRole: {
				label: "Role",
				placeholder: "Select a role",
				none: "No role",
				custom: "Custom",
				required: "Role is required",
			},
			zigTag: {
				label: "ZigTag",
				placeholder: "Enter the ZigTag number",
				nonnegative: "The value must be greater than or equal to 0",
			},
			permissions: {
				operationalPermissions: "PDV operational permissions",
				managerialPermissions: "PDV management permissions",
				restrictivePermissions: "PDV restrictions",
				permissionsOrg: "Dashboard access",
			},
			type: {
				label: "Type of employee",
				placeholder: "No one",
			},
		},
		moreThanOneEmployee: {
			title: "Add more than one collaborator",
			initialValue: "Enter the initial value",
			finalValue: "Enter the final value",
			validationUntil: "The final value must be greater than the initial value",
			negativeValue: "The values must be greater than 0",
			required: "The fields are required",
			nonnegative: "The value must be greater than or equal to 0",
			since: {
				label: "Since",
			},
			until: {
				label: "Until",
			},
		},
		changePlace: {
			title: "Change place of collaborators",
			description:
				"Select a new place for the selected collaborators: {{ employeeNames }}",
			place: {
				label: "Place",
				placeholder: "Select a place",
				required: "Place is required",
			},
			mainBar: {
				label: "Main bar",
				placeholder: "Select a bar",
			},
			barIds: {
				label: "Bars",
				placeholder: "Select one or more bars",
			},
		},
	},
	deleteEmployeeModal: {
		title: "Do you want to delete the collaborator?",
		description:
			"By deleting the collaborator, their login will be invalidated. This action cannot be undone.",
		buttonCancel: "Close",
		buttonConfirm_one: "Delete collaborator ({{ count }})",
		buttonConfirm_other: "Delete collaborators ({{ count }})",
	},
	reportPrint: {
		title: "Import Employees",
		btnDownloadTemplate: "Download Template",
		spreadsheet: "Import Spreadsheet",
		sheetFileName: "Employees",
		sheetColumns: {
			name: { label: "Employee Name", sample: "William" },
			username: { label: "Username", sample: "willzig" },
			password: { label: "Password", sample: "123456" },
			zigTag: { label: "ZigTag (optional)", sample: "123Z7JH" },
			fichasAmount: { label: "Number of Sheets", sample: "2" },
			multiple: { label: "Batch Quantity", sample: "1" },
		},
		role: {
			label: "Role",
			placeholder: "Select a role",
			none: "No role",
			custom: "Custom",
		},
		pub: {
			label: "Bars",
			labelMain: "Main Bar",
			placeholder: "Select a bar",
			none: "No bar",
		},
		permissions: {
			label: "Permissions",
		},
		info:
			"All employees will be added to {{ placeName }} with the role and bar selected above",
		discard: "Discard Import",
		finalize: "Finalize Import",
	},
	validateTeamReport: {
		emptySheet: "No data found in the sheet",
		existingEmployees: "Existing employees: {{ usernames }}",
		duplicateUsernames: "Duplicate logins found: {{ usernames }}",
		invalidSheet: "Invalid data found: {{ errors }}",
		invalidRow: "Row {{ index }}: {{ errors }}",
		invalid: {
			name: "Invalid name",
			username: "Invalid login",
			usernameChars: "Login contains invalid characters",
			password: "Invalid password",
			multiple: "Invalid multiple value",
			multipleGreaterThan: "Multiple greater than allowed",
		},
	},
};
